<!-- eslint-disable no-undef -->
<!-- eslint-disable no-undef -->
<template>
  <div>
    <div class="bannerBox">
      <h2>关于我们</h2>
      <h5>国内领先的电子认证服务和信息安全解决方案提供商</h5>
    </div>
    <div class="contactWay">
      <div>
        <dl>
          <dt>客服热线</dt>
          <dd><a href="tel:400-030-2013">400-030-2013</a></dd>
        </dl>
        <dl>
          <dt>传真</dt>
          <dd>0531-88208901</dd>
        </dl>
        <dl>
          <dt>电子邮箱</dt>
          <dd>zxca@zxca.cn</dd>
        </dl>
        <dl>
          <dt>联系人</dt>
          <dd>孙女士/张先生</dd>
        </dl>
      </div>
    </div>
    <div class="companyProfile">
      <h2>山东豸信认证服务有限公司</h2>
      <p>
        山东豸信认证服务有限公司是国内领先的电子认证服务和信息安全解决方案提供商，主要为云计算、物联网、新一代移动通信、下一代互联网等新一代信息技术应用领域提供电子认证服务、产品研发及可管理的信息安全服务。豸信CA具有国家工业和信息化部颁发的电子认证服务许可资质。
      </p>
      <p>
        作为领先的电子认证解决方案提供商，公司遵照《中华人民共和国电子签名法》的要求和相关管理规定，为用户提供“全方位”电子认证解决方案，以及基于云计算的安全技术研发服务、安全集成服务以及信息安全咨询、规划、培训等全方位的信息安全服务，建立起覆盖全国的电子认证服务网络和较完善的电子认证产品体系。为电子政务、电子商务、社会事业信息化和企业信息化等的快速发展，构建安全、可靠的信任环境。应用领域覆盖政府、金融机构等，在云计算领域的市场占有率位居行业前列，建立了市场领先优势。
      </p>
    </div>
    <div class="developmentHistory">
      2013年10月公司注册成立以来，一直秉承“公平正义、明辨真伪”的原则，秉承“立足企业前沿、超越市场竞争；锐意开拓进取、力求合作共赢”的经营理念。注册以来获得多项荣誉及资质证书，2015年6月荣获“电子认证服务使用密码许可证”；2016年6月获得“电子认证服务许可证”；2017年1月荣获“电子政务电子认证服务许可证”；2022年1月，公司成功更名为“山东豸信认证服务有限公司”。
    </div>
    <div class="certification">
      <h2>资质证书</h2>
      <div class="aptitudeBox" v-for="(item,index) in certification" :key="index">
        <img :src="require(`@/assets/image/aboutUs/credential${index+1}.png`)" alt="资质证书">
        <h3>{{item}}</h3>
      </div>
    </div>
    <div class="officeSpace">
      <h2>联系地址</h2>
      <div class="station">
        <div class="map" id="mapContainer"></div>
        <img src="@/assets/image/aboutUs/officeSpace.png" alt="公司场地">
        <div class="companyAddress">
          <p><span>公司地址：</span>山东省济南市历城区唐冶绿地汇中心23号楼101</p>
          <p><span>邮政编码：</span>250014</p>
          <p><span>服务投诉：</span>400-040-2013</p>
        </div>
      </div>
    </div>
    <v-networkSecurity />
  </div>
</template>

<script>
import networkSecurity from '../../../components/networkSecurity.vue';
export default {
  data () {
    return {
      certification: [
        '电子认证服务许可证',
        '电子认证服务密码使用许可证',
        '电子政务电子认证服务许可',
        '企业营业执照',
        '软件企业证书',
        '软件产品证书',
        'ISO27001',
        '高新技术企业证书',
        '山东省优秀软件企业证书',
        'CMMI3证书（软件能力成熟度集成模型)'
      ],
    }
  },
  components: {
    'v-networkSecurity': networkSecurity,
  },
  mounted () {
    let script = document.createElement('script')
    script.type = 'text/javascript'
    script.src =
      '//webapi.amap.com/maps?v=1.3&key=a3b9c7defdef43bb29e8992487e8161a'
    document.body.appendChild(script)
    script.onload = () => {
      // eslint-disable-next-line no-undef
      let center = { lng: 117.223304, lat: 36.692868 }
      let features = [
        {
          icon: 'pot',
          color: 'red',
          name: '山东豸信认证服务有限公司',
          desc:
            '山东省济南市历城区唐冶绿地城市大脑数字产业小镇AD23号楼101（商墅）',
          lnglat: {
            Q: 36.692864303,
            R: 117.223326998,
            lng: 117.223304,
            lat: 36.692861
          },
          offset: { x: -9, y: -31 },
          type: 'Marker'
        }
      ]
      this.mapInit('mapContainer', center, features)
    }
  },
  methods: {
    mapInit (id, center, features) {
      // eslint-disable-next-line no-undef
      let _AMap = AMap
      let infoWindow
      let map
      let level = 16

      function mapFeatureClick (e) {
        if (!infoWindow) {
          infoWindow = new _AMap.InfoWindow({ autoMove: true, isCustom: false })
        }
        var extData = e.target.getExtData()
        infoWindow.setContent(
          "<div class='myinfowindow'><h5>" +
          extData.name +
          '</h5></div>'
        )
        infoWindow.open(map, e.lnglat)
      }
      function loadFeatures () {
        var icon = new _AMap.Icon({
          size: new _AMap.Size(20, 30), // 图标尺寸
          image: require('@/assets/image/aboutUs/mark_rs.png'), // Icon的图像
          imageSize: new _AMap.Size(20, 30) // 根据所设置的大小拉伸或压缩图片
        })
        for (var feature, data, i = 0, len = features.length; i < len; i++) {
          data = features[i]
          feature = new _AMap.Marker({
            map: map,
            position: new _AMap.LngLat(data.lnglat.lng, data.lnglat.lat),
            zIndex: 3,
            extData: data,
            offset: new _AMap.Pixel(data.offset.x, data.offset.y),
            title: data.name,
            icon: icon
          })
          if (feature) {
            _AMap.event.addListener(feature, 'click', mapFeatureClick)
          }
        }
        feature.on('click', function () {
          feature.markOnAMAP({
            name: '山东豸信认证服务有限公司',
            position: feature.getPosition()
          })
        })
      }

      map = new _AMap.Map(id, {
        center: new _AMap.LngLat(center.lng, center.lat),
        level: level,
        keyboardEnable: true,
        dragEnable: true,
        scrollWheel: true,
        doubleClickZoom: true
      })
      loadFeatures()

      map.on('complete', function () {
        map.plugin(['AMap.ToolBar', 'AMap.OverView', 'AMap.Scale'], function () {
          map.addControl(new _AMap.Scale())
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.bannerBox {
  width: 7.5rem;
  height: 9.8rem;
  text-align: center;
  background: #2241d1 url('~@/assets/image/aboutUs/aboutUs-banner.png') left
    bottom no-repeat;
  background-size: 7.5rem 4.14rem;
  h2 {
    padding-top: 2.6rem;
    font-size: 0.48rem;
    color: #ffffff;
    line-height: 0.67rem;
    margin-bottom: 0.24rem;
  }
  h5 {
    font-size: 0.28rem;
    font-weight: 400;
    color: #ffffff;
    line-height: 0.44rem;
  }
}
.contactWay {
  height: 4.46rem;
  position: relative;
  > div {
    position: absolute;
    top: -0.26rem;
    left: 0.32rem;
  }
  dl {
    width: 3.32rem;
    height: 1.6rem;
    background: #f9fbff;
    box-shadow: 0px 4px 10px 0px rgba(31, 37, 81, 0.11);
    border-radius: 4px;
    border: 0.03rem solid #ffffff;
    padding: 0.39rem 0.72rem;
    box-sizing: border-box;
    margin: 0 0.22rem 0.32rem 0;
    display: inline-block;
    background: url('~@/assets/image/aboutUs/contact-way.png') left top
      no-repeat;
    background-size: 100% 100%;
    &:nth-child(2n) {
      margin-right: 0;
    }
  }
  dt {
    font-size: 0.32rem;
    font-weight: 600;
    color: #303b50;
    line-height: 0.45rem;
    margin-bottom: 0.15rem;
  }
  dd {
    font-size: 0.24rem;
    color: #8d96a3;
    line-height: 0.22rem;
  }
  a {
    color: #8d96a3;
  }
}
.companyProfile {
  padding: 0 0.32rem;
  box-sizing: border-box;
  h2 {
    font-size: 0.48rem;
    color: #293c54;
    line-height: 0.67rem;
    padding-bottom: 0.22rem;
    position: relative;
    text-align: center;
    margin-bottom: 0.46rem;
    &::after {
      content: '';
      width: 0.9rem;
      height: 0.06rem;
      background: #4685f9;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  p {
    font-size: 0.28rem;
    color: #68747d;
    line-height: 0.56rem;
    &:nth-child(2) {
      margin-bottom: 0.7rem;
    }
    &:nth-child(3) {
      margin-bottom: 0.4rem;
    }
  }
}
.developmentHistory {
  width: 7.5rem;
  height: 3.72rem;
  background: #2241d1 url('~@/assets/image/aboutUs/introduce-bg.png') left top
    no-repeat;
  background-size: 100% 100%;
  padding: 24px 31px;
  box-sizing: border-box;
  font-size: 0.28rem;
  color: #ffffff;
  line-height: 0.4rem;
}
.certification {
  padding: 0.9rem 0.64rem 0;
  box-sizing: border-box;
  text-align: center;
  h2 {
    font-size: 0.48rem;
    color: #293c54;
    line-height: 0.67rem;
    margin-bottom: 0.64rem;
    position: relative;
    &::before {
      content: '';
      width: 1.21rem;
      height: 0.27rem;
      position: absolute;
      top: 0.24rem;
      left: 0.62rem;
      background: url('~@/assets/image/aboutUs/certification-icon.png') left top
        no-repeat;
      background-size: 100% 100%;
    }
    &::after {
      content: '';
      width: 1.21rem;
      height: 0.27rem;
      position: absolute;
      top: 0.24rem;
      right: 0.62rem;
      background: url('~@/assets/image/aboutUs/certification-icon.png') left top
        no-repeat;
      background-size: 100% 100%;
      transform: rotate(180deg);
    }
  }
}
.aptitudeBox {
  width: 6.22rem;
  height: 5.46rem;
  background: linear-gradient(180deg, #f8fbff 0%, #d0dfff 0%, #f5f7ff 20%);
  box-shadow: 0px 0.04rem 0.16rem 0px rgba(31, 37, 81, 0.11);
  border-radius: 0.04rem;
  border: 0.02rem solid #ffffff;
  backdrop-filter: blur(18px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 0.4rem;
  &:last-child {
    margin-bottom: 0;
  }
  img {
    width: 4.7rem;
    height: 3.32rem;
  }
  h3 {
    margin-top: 0.42rem;
    font-size: 0.28rem;
    color: #2a2b2c;
    line-height: 0.4rem;
  }
}
.officeSpace {
  padding: 0.9rem 0.32rem 0;
  box-sizing: border-box;
  h2 {
    font-size: 0.48rem;
    color: #293c54;
    line-height: 0.67rem;
    margin-bottom: 0.64rem;
    text-align: center;
    position: relative;
    &::before {
      content: '';
      width: 1.21rem;
      height: 0.27rem;
      position: absolute;
      top: 0.24rem;
      left: 0.62rem;
      background: url('~@/assets/image/aboutUs/certification-icon.png') left top
        no-repeat;
      background-size: 100% 100%;
    }
    &::after {
      content: '';
      width: 1.21rem;
      height: 0.27rem;
      position: absolute;
      top: 0.24rem;
      right: 0.62rem;
      background: url('~@/assets/image/aboutUs/certification-icon.png') left top
        no-repeat;
      background-size: 100% 100%;
      transform: rotate(180deg);
    }
  }
}
.station {
  width: 6.86rem;
  height: 4.71rem;
  background: #ffffff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.5);
  border-radius: 1px;
  position: relative;
  img {
    width: 2.4rem;
    position: absolute;
    top: 0;
    left: 0;
  }
}
.map {
  width: 6.86rem;
  height: 2.6rem;
}
.companyAddress {
  padding: 0.32rem 0.24rem;
  box-sizing: border-box;
  p {
    font-size: 0.24rem;
    color: #333333;
    line-height: 0.33rem;
    margin-bottom: 0.24rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
  span {
    font-weight: bold;
    color: #333333;
  }
}
</style>